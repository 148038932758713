/*menu*/
.menu{
	@include clr;
	list-style: none;
	&__item{
		float: left;
		margin-left: 55px;
		@include fch(l);
		&_current{
			.menu__a{
				border-bottom-color: $gn;
			}
		}
	}
	&__a{
		font-size: 18px;
		font-weight: 500;
		color: $dgy;
		line-height: normal;
		text-decoration: none;
		border-bottom: 2px solid transparent;
		transition: .21s;
		@media(min-width: 1001px){
			&:hover{
				color: $gn;
			}
		}
	}
	@media(max-width: 1000px){
		padding: 10px 25px;
		&__item{
			float: none;
			text-align: center;
			margin: 25px 0;
		}
	}
}