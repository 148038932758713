@font-face {
	font-family: 'helveticaneue';
	src: url('../fonts/helveticaneuecyr-thin-webfont.eot');
	src: url('../fonts/helveticaneuecyr-thin-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/helveticaneuecyr-thin-webfont.woff2') format('woff2'),
	url('../fonts/helveticaneuecyr-thin-webfont.woff') format('woff'),
	url('../fonts/helveticaneuecyr-thin-webfont.ttf') format('truetype'),
	url('../fonts/helveticaneuecyr-thin-webfont.svg#helveticaneuecyrthin') format('svg');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'helveticaneue';
	src: url('../fonts/helveticaneuecyr-thinitalic-webfont.eot');
	src: url('../fonts/helveticaneuecyr-thinitalic-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/helveticaneuecyr-thinitalic-webfont.woff2') format('woff2'),
	url('../fonts/helveticaneuecyr-thinitalic-webfont.woff') format('woff'),
	url('../fonts/helveticaneuecyr-thinitalic-webfont.ttf') format('truetype'),
	url('../fonts/helveticaneuecyr-thinitalic-webfont.svg#helveticaneuecyrthin_italic') format('svg');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'helveticaneue';
	src: url('../fonts/helveticaneuecyr-light-webfont.eot');
	src: url('../fonts/helveticaneuecyr-light-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/helveticaneuecyr-light-webfont.woff2') format('woff2'),
	url('../fonts/helveticaneuecyr-light-webfont.woff') format('woff'),
	url('../fonts/helveticaneuecyr-light-webfont.ttf') format('truetype'),
	url('../fonts/helveticaneuecyr-light-webfont.svg#helveticaneuecyrlight') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'helveticaneue';
	src: url('../fonts/helveticaneuecyr-lightitalic-webfont.eot');
	src: url('../fonts/helveticaneuecyr-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/helveticaneuecyr-lightitalic-webfont.woff2') format('woff2'),
	url('../fonts/helveticaneuecyr-lightitalic-webfont.woff') format('woff'),
	url('../fonts/helveticaneuecyr-lightitalic-webfont.ttf') format('truetype'),
	url('../fonts/helveticaneuecyr-lightitalic-webfont.svg#helveticaneuecyrlight_italic') format('svg');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'helveticaneue';
	src: url('../fonts/helveticaneuecyr-roman-webfont.eot');
	src: url('../fonts/helveticaneuecyr-roman-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/helveticaneuecyr-roman-webfont.woff2') format('woff2'),
	url('../fonts/helveticaneuecyr-roman-webfont.woff') format('woff'),
	url('../fonts/helveticaneuecyr-roman-webfont.ttf') format('truetype'),
	url('../fonts/helveticaneuecyr-roman-webfont.svg#helveticaneuecyrroman') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'helveticaneue';
	src: url('../fonts/helveticaneuecyr-italic-webfont.eot');
	src: url('../fonts/helveticaneuecyr-italic-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/helveticaneuecyr-italic-webfont.woff2') format('woff2'),
	url('../fonts/helveticaneuecyr-italic-webfont.woff') format('woff'),
	url('../fonts/helveticaneuecyr-italic-webfont.ttf') format('truetype'),
	url('../fonts/helveticaneuecyr-italic-webfont.svg#helveticaneuecyritalic') format('svg');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'helveticaneue';
	src: url('../fonts/helveticaneuecyr-medium-webfont.eot');
	src: url('../fonts/helveticaneuecyr-medium-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/helveticaneuecyr-medium-webfont.woff2') format('woff2'),
	url('../fonts/helveticaneuecyr-medium-webfont.woff') format('woff'),
	url('../fonts/helveticaneuecyr-medium-webfont.ttf') format('truetype'),
	url('../fonts/helveticaneuecyr-medium-webfont.svg#helveticaneuecyrmedium') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'helveticaneue';
	src: url('../fonts/helveticaneuecyr-mediumitalic-webfont.eot');
	src: url('../fonts/helveticaneuecyr-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/helveticaneuecyr-mediumitalic-webfont.woff2') format('woff2'),
	url('../fonts/helveticaneuecyr-mediumitalic-webfont.woff') format('woff'),
	url('../fonts/helveticaneuecyr-mediumitalic-webfont.ttf') format('truetype'),
	url('../fonts/helveticaneuecyr-mediumitalic-webfont.svg#helveticaneuecyrmedium_italic') format('svg');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'helveticaneue';
	src: url('../fonts/helveticaneuecyr-bold-webfont.eot');
	src: url('../fonts/helveticaneuecyr-bold-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/helveticaneuecyr-bold-webfont.woff2') format('woff2'),
	url('../fonts/helveticaneuecyr-bold-webfont.woff') format('woff'),
	url('../fonts/helveticaneuecyr-bold-webfont.ttf') format('truetype'),
	url('../fonts/helveticaneuecyr-bold-webfont.svg#helveticaneuecyrbold') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'helveticaneue';
	src: url('../fonts/helveticaneuecyr-bolditalic-webfont.eot');
	src: url('../fonts/helveticaneuecyr-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/helveticaneuecyr-bolditalic-webfont.woff2') format('woff2'),
	url('../fonts/helveticaneuecyr-bolditalic-webfont.woff') format('woff'),
	url('../fonts/helveticaneuecyr-bolditalic-webfont.ttf') format('truetype'),
	url('../fonts/helveticaneuecyr-bolditalic-webfont.svg#helveticaneuecyrbold_italic') format('svg');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'helveticaneue';
	src: url('../fonts/helveticaneuecyr-black-webfont.eot');
	src: url('../fonts/helveticaneuecyr-black-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/helveticaneuecyr-black-webfont.woff2') format('woff2'),
	url('../fonts/helveticaneuecyr-black-webfont.woff') format('woff'),
	url('../fonts/helveticaneuecyr-black-webfont.ttf') format('truetype'),
	url('../fonts/helveticaneuecyr-black-webfont.svg#helveticaneuecyrblack') format('svg');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'helveticaneue';
	src: url('../fonts/helveticaneuecyr-blackitalic-webfont.eot');
	src: url('../fonts/helveticaneuecyr-blackitalic-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/helveticaneuecyr-blackitalic-webfont.woff2') format('woff2'),
	url('../fonts/helveticaneuecyr-blackitalic-webfont.woff') format('woff'),
	url('../fonts/helveticaneuecyr-blackitalic-webfont.ttf') format('truetype'),
	url('../fonts/helveticaneuecyr-blackitalic-webfont.svg#helveticaneuecyrblack_italic') format('svg');
	font-weight: 900;
	font-style: italic;
}