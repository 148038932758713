%svg-common {
	background: url("../images/sprite.svg") no-repeat;
}

%svg-arrow-right {
	@extend %svg-common;
	background-position: 99.96646171045276% 0;
}

%svg-arrow-right-dims {
	width: 468px;
	height: 532px;
}

%svg-css3 {
	@extend %svg-common;
	background-position: 39.42028985507246% 66.66666666666667%;
}

%svg-css3-dims {
	width: 532px;
	height: 532px;
}

%svg-envelope {
	@extend %svg-common;
	background-position: 0 100%;
}

%svg-envelope-dims {
	width: 532px;
	height: 532px;
}

%svg-facebook {
	@extend %svg-common;
	background-position: 99.96646171045276% 33.333333333333336%;
}

%svg-facebook-dims {
	width: 468px;
	height: 532px;
}

%svg-facebook-messenger {
	@extend %svg-common;
	background-position: 59.474566797093345% 100%;
}

%svg-facebook-messenger-dims {
	width: 468px;
	height: 532px;
}

%svg-git {
	@extend %svg-common;
	background-position: 73.80659586361095% 33.333333333333336%;
}

%svg-git-dims {
	width: 468px;
	height: 532px;
}

%svg-gulp {
	@extend %svg-common;
	background-position: 0 66.66666666666667%;
}

%svg-gulp-dims {
	width: 276px;
	height: 532px;
}

%svg-html5 {
	@extend %svg-common;
	background-position: 14.894765245547761% 66.66666666666667%;
}

%svg-html5-dims {
	width: 404px;
	height: 532px;
}

%svg-js {
	@extend %svg-common;
	background-position: 73.80659586361095% 0;
}

%svg-js-dims {
	width: 468px;
	height: 532px;
}

%svg-mysql {
	@extend %svg-common;
	background-position: 70.26086956521739% 66.66666666666667%;
}

%svg-mysql-dims {
	width: 532px;
	height: 532px;
}

%svg-php {
	@extend %svg-common;
	background-position: 41.35253600500939% 0;
}

%svg-php-dims {
	width: 660px;
	height: 532px;
}

%svg-sass {
	@extend %svg-common;
	background-position: 0 0;
}

%svg-sass-dims {
	width: 660.4px;
	height: 532px;
}

%svg-share-square {
	@extend %svg-common;
	background-position: 0 33.333333333333336%;
}

%svg-share-square-dims {
	width: 596px;
	height: 532px;
}

%svg-telegram-plane {
	@extend %svg-common;
	background-position: 99.96646171045276% 66.66666666666667%;
}

%svg-telegram-plane-dims {
	width: 468px;
	height: 532px;
}

%svg-viber {
	@extend %svg-common;
	background-position: 30.840579710144926% 100%;
}

%svg-viber-dims {
	width: 532px;
	height: 532px;
}

%svg-vk {
	@extend %svg-common;
	background-position: 35.88199879590608% 33.333333333333336%;
}

%svg-vk-dims {
	width: 596px;
	height: 532px;
}

%svg-wordpress {
	@extend %svg-common;
	background-position: 88.81159420289855% 100%;
}

%svg-wordpress-dims {
	width: 532px;
	height: 532px;
}

