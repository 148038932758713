@import "sprite-extends";

//gulp svg sprite bg size mixin
$sprite-width: 2257;
@mixin bgs($icon-svg-w: 1) {
	background-size: ($sprite-width / $icon-svg-w) * 100% auto;
}

/*sprites*/
.arrow-btn::after{
	@extend %svg-arrow-right;
	@include bgs(468);
}
.html5-icon{
	@extend %svg-html5;
	@include bgs(404);
}
.css3-icon{
	@extend %svg-css3;
	@include bgs(512);
}
.js-icon{
	@extend %svg-js;
	@include bgs(448);
}
.php-icon{
	@extend %svg-php;
	@include bgs(640);
}
.mysql-icon{
	@extend %svg-mysql;
	@include bgs(512);
}
.wordpress-icon{
	@extend %svg-wordpress;
	@include bgs(512);
}
.gulp-icon{
	@extend %svg-gulp;
	@include bgs(256);
}
.sass-icon{
	@extend %svg-sass;
	@include bgs(640);
}
.git-icon{
	@extend %svg-git;
	@include bgs(448);
}

.email-link::before{
	@extend %svg-envelope;
	@include bgs(532);
}
.viber-link::before{
	@extend %svg-viber;
	@include bgs(532);
}
.fbk-link::before{
	@extend %svg-facebook;
	@include bgs(468);
}
.teleg-link::before{
	@extend %svg-telegram-plane;
	@include bgs(468);
}
.messenger-link::before{
	@extend %svg-facebook-messenger;
	@include bgs(468);
}