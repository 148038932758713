//rem
$base-font-size: 16;
@function rem($px, $base: $base-font-size) {
    @return ($px / $base) * 1rem;
}

//em
@function em($px, $base: $base-font-size) {
    @return ($px / $base) * 1em;
}

//percent
@function per($px, $base) {
    @return $px * (100 / $base) * 1%;
}

//vh
@function vh($px, $base) {
    @return $px * (100 / $base) * 1vh;
}