/*Lang*/
.lang{
	margin-left: 90px;
	color: $dgy;
	&__link{
		line-height: normal;
		text-decoration: none;
		border-bottom: 1px solid transparent;
		transition: .21s;
		&_current{
			border-bottom-color: $gn;
		}
		@media(min-width: 1001px){
			&:hover{
				color: $gn;
			}
		}
		&-sep{
			line-height: normal;
			margin: 0 3px;
		}
	}
	@media(max-width: 1000px){
		margin: 0;
		text-align: center;
	}
}