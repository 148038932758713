/* main */
.main{
	&, &__full-page{
		padding-top: 65px;
	}
	&__full-page{
		@extend %fwh;
	}
}

/* first screen */
.first-screen{
	overflow: hidden;
}

/* section */
.section{
	padding-top: 30px;
	padding-bottom: 30px;
}

/* list */
.list{
	list-style: none;
	li{
		padding-left: 30px;
		margin-top: 30px;
		@include fch(t);
		position: relative;
		&:before{
			@extend %ab;
			position: absolute;
		}
	}
}
ul.list{
	li{
		&:before{
			width: 9px;
			height: 9px;
			left: 3px;
			top: 6px;
			background: $rd;
		}
	}
}
ol.list{
	counter-reset: listCounter;
	li{
		&:before{
			counter-increment: listCounter;
			content: counter(listCounter)".";
			color: $be;
			font-weight: 700;
			top: 0;
			left: 0;
		}
	}
}

/* image */
.fullwidth-img{
	width: 100%;
	height: auto;
}

/* title */
.title{
	font-size: 26px;
	font-weight: 700;
	line-height: 1.3;
	color: $gn;
	margin-bottom: 25px;
	@media(max-width: 750px){
		font-size: 22px;
		margin-bottom: 0;
	}
}

/* paragraph */
.par{
	line-height: 1.5;
	margin-top: 25px;
	@include fch(t);
}

/* article */
.article{
	h2{
		font-size: 22px;
		font-weight: 700;
		line-height: 1.3;
		margin: 45px 0 30px;
		@include fch(t);
	}
	p{
		line-height: 1.5;
		margin-top: 25px;
		@include fch(t);
	}
	ul, ol{
		list-style: none;
		margin-top: 25px;
		@include fch(t);
		li{
			line-height: 1.5;
			padding-left: 30px;
			margin-top: 15px;
			@include fch(t);
			position: relative;
			&:before{
				@extend %ab;
				position: absolute;
			}
		}
	}
	ul{
		li{
			&:before{
				width: 9px;
				height: 9px;
				border-radius: 50%;
				left: 3px;
				top: 7px;
				background: $gngy;
			}
		}
	}
	ol{
		counter-reset: listCounter;
		li{
			&:before{
				counter-increment: listCounter;
				content: counter(listCounter)".";
				color: $be;
				font-weight: 700;
				top: 0;
				left: 0;
			}
		}
	}
	a:not([class*="link"]){
		color: $be;
		text-decoration: none;
		border-bottom: 1px solid $be;
		transition: .21s;
		&:hover{
			border-bottom-color: transparent;
		}
	}
	@media(max-width: 750px){
		h2{
			font-size: 18px;
			margin: 35px 0 25px;
		}
		p, ul, ol{
			margin-top: 20px;
		}
	}
}

/* work */
.work{
	padding-bottom: 35px;
	margin-bottom: 35px;
	position: relative;
	&__thumb{
		border: 2px solid $gy;
		img{
			width: 100%;
			height: auto;
		}
	}
	&__info{
		padding: 15px 0 0 15px;
	}
	&:before{
		@extend %ab;
		position: absolute;
		bottom: 0;
		left: 10px;
		right: 10px;
		height: 1px;
		background: $gy;
	}
	&:last-child{
		margin-bottom: 0;
		padding-bottom: 0;
		&:before{
			display: none;
		}
	}
	&__title{
		font-size: 18px;
		font-weight: 500;
		line-height: 1.3;
		margin-bottom: 5px;
	}
	&__desc{
		font-size: 14px;
		line-height: 1.3;
		margin-bottom: 25px;
		color: $dgy;
	}
	&__techn{
		margin-bottom: 25px;
	}
	&__icon{
		margin: 0 10px;
		vertical-align: middle;
	}
	
	@media(max-width: 1000px){
		&__info{
			padding: 0;
		}
		&__icon{
			margin: 0 7px 15px;
		}
	}
	@media(max-width: 750px){
		padding-bottom: 15px;
		margin-bottom: 15px;
	}
}

/*person*/
.person{
	&__thumb{
		border: 2px solid $gy;
		img{
			width: 100%;
			height: auto;
		}
	}
	
	&__info{
		padding: 15px 15px 0 0;
	}
	
	@media(max-width: 1000px){
		&__info{
			padding: 0;
			margin-bottom: 15px;
		}
		
	}
}

/*skills*/
.skills{
	&__icon{
		margin: 0 11px 15px;
		vertical-align: middle;
	}
}

/* contacts */
.contacts{
	&__row{
		margin-bottom: 30px;
	}
}