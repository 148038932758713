@mixin clr() {
	&:after{
		display: block;
		content: "";
		width: 100%;
		clear: both;
	}
}

//fixProportion
@mixin fixprop($width: 1, $height: 1) {
	&:before{
		display: block;
		content: "";
		width: 100%;
		padding-bottom: $height * (100 / $width) * 1%;
	}
}

//first child
@mixin fch($m: default) {
	&:first-child{
		@if $m == t {
			margin-top: 0 !important;
		} @else if $m == l {
			margin-left: 0 !important;
		}
	}
}