%ico{
	display: inline-block;
	position: relative;
	&:before{
		display: block;
		content: "";
		position: absolute;
		left: 0;
	}
}

.icon{
	@extend %ico;
	min-height: em(13);
	padding-left: em(30);
	&:before{
		width: em(25);
		height: em(21);
		top: em(-5);
	}
}

.html5-icon{
	@extend %ico;
	width: 35px;
	height: 44px;
}
.css3-icon{
	@extend %ico;
	width: 38px;
	height: 39px;
}
.js-icon{
	@extend %ico;
	width: 35px;
	height: 38px;
}
.php-icon{
	@extend %ico;
	width: 52px;
	height: 40px;
}
.mysql-icon{
	@extend %ico;
	width: 37px;
	height: 38px;
}
.wordpress-icon{
	@extend %ico;
	width: 38px;
	height: 38px;
}
.gulp-icon{
	@extend %ico;
	width: 26px;
	height: 51px;
	position: relative;
	bottom: 5px;
}
.sass-icon{
	@extend %ico;
	width: 43px;
	height: 36px;
}
.git-icon{
	@extend %ico;
	width: 35px;
	height: 40px;
}