/*text color*/
.c-red{
	color: $rd !important;
}
.c-gray{
	color: $gy !important;
}

/*text align*/
.ta-l{
	text-align: left !important;
}
.ta-c{
	text-align: center !important;
}
.ta-r{
	text-align: right !important;
}

/*background*/
.bg-gray{
	background: $gy;
}

/*border*/
.brad-5{
	border-radius: 5px;
}

/*width*/
.maxw-100{
	max-width: 100px;
}

/*height*/
.minh-100per{
	min-height: 100%;
}

/*position*/
.pos-r{
	position: relative !important;
}

/*marging*/
.mt{
	&-0{
		margin-top: 0 !important;
	}
}
.mb{
	&-0{
		margin-bottom: 0 !important;
	}
}
.ml{
	&-0{
		margin-left: 0 !important;
	}
}
.mr{
	&-0{
		margin-right: 0 !important;
	}
}
.m{
	&-0{
		margin: 0 !important;
	}
}

/*padding*/
.pt{
	&-0{
		padding-top: 0 !important;
	}
}
.pb{
	&-0{
		padding-bottom: 0 !important;
	}
}
.pl{
	&-0{
		padding-left: 0 !important;
	}
}
.pr{
	&-0{
		padding-right: 0 !important;
	}
}
.p{
	&-x{
		&-0{
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}
	&-y{
		&-0{
			padding-top: 0 !important;
			padding-bottom: 0 !important;
		}
	}
	&-0{
		padding: 0 !important;
	}
}

/*display*/
.hidden{
	display: none !important;
}

@media(max-width: 1600px){
	.lg{
		&-hidden{
			display: none !important;
		}
	}
}

@media(min-width: 1201px){
	.lg-x{
		&-hidden{
			display: none !important;
		}
	}
}

@media(max-width: 1200px){
	.md{
		&-hidden{
			display: none !important;
		}
	}
}

@media(min-width: 1001px){
	.md-x{
		&-hidden{
			display: none !important;
		}
	}
}

@media(max-width: 1000px){
	.sm{
		&-hidden{
			display: none !important;
		}

		&-p{
			&-x{
				&-15{
					padding-left: 15px !important;
					padding-right: 15px !important;
				}
				&-0{
					padding-left: 0 !important;
					padding-right: 0 !important;
				}
			}
			&-y{
				&-0{
					padding-top: 0 !important;
					padding-bottom: 0 !important;
				}
			}
			&-0{
				padding: 0 !important;
			}
		}

		&-m-x-0{
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
		&-m-y-0{
			margin-top: 0 !important;
			margin-bottom: 0 !important;
		}
		&-m-0{
			margin: 0 !important;
		}
	}
}

@media(min-width: 751px){
	.sm-x{
		&-hidden{
			display: none !important;
		}
	}
}

@media(max-width: 750px){
	.xs{
		&-hidden{
			display: none !important;
		}
	}
}