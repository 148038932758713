%btn{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 1px;
	color: #FFF;
	padding: 0 20px;
	height: 43px;
	line-height: normal;
	text-align: center;
	text-decoration: none;
	vertical-align: bottom;
	border: none;
	background: $bk;
	user-select: none;
	position: relative;
	transition: .5s;
	span{
		display: block;
		width: 100%;
	}
}

%btn-icon{
	display: block;
	content: "";
	position: absolute;
	top: 50%;
}

//default btn
.btn{
	@extend %btn;
	width: 150px;
}

//arrow btn
.arrow-btn{
	@extend %btn;
	padding-right: 43px;
	background: $gn;
	&:after{
		@extend %btn-icon;
		width: 15px;
		height: 16px;
		right: 15px;
		margin-top: -8px;
	}
	&:hover{
		opacity: 0.7;
	}
	@media(max-width: 750px){
		width: 100%;
		padding-left: 43px;
	}
}

/* icon button */
%icon-btn{
	display: inline-block;
	border: none;
	background: none;
	-webkit-appearance: none;
	transition: .5s;
	&:hover{
		opacity: .55;
	}
}

.vk-btn{
	@extend %icon-btn;
	width: 46px;
	height: 37px;
}
.fb-btn{
	@extend %icon-btn;
	width: 32px;
	height: 36px;
}
.tw-btn{
	@extend %icon-btn;
	width: 35px;
	height: 35px;
}
.ok-btn{
	@extend %icon-btn;
	width: 21px;
	height: 35px;
}

/* icon link */
%icon-link{
	display: inline-block;
	position: relative;
	text-decoration: none;
	border-bottom: 1px solid $gngy;
	margin-left: 48px;
	transition: .21s;
	&:hover{
		border-bottom-color: transparent;
	}
	&:before{
		display: block;
		content: "";
		position: absolute;
		left: -48px;
		top: 50%;
	}
}
.email-link{
	@extend %icon-link;
	&:before{
		width: 35px;
		height: 32px;
		margin-top: -15px;
	}
}
.viber-link{
	@extend %icon-link;
	&:before{
		width: 35px;
		height: 37px;
		margin-top: -18px;
	}
}
.fbk-link{
	@extend %icon-link;
	&:before{
		width: 32px;
		height: 35px;
		margin-top: -18px;
	}
}
.teleg-link{
	@extend %icon-link;
	&:before{
		width: 35px;
		height: 35px;
		margin-top: -17px;
	}
}
.messenger-link{
	@extend %icon-link;
	&:before{
		width: 32px;
		height: 35px;
		margin-top: -16px;
	}
}

/* link */
%link{
	text-decoration: none;
	border-bottom: 1px solid;
	transition: .21s;
	&:hover{
		border-bottom-color: transparent;
	}
}
.link{
	@extend %link;
	color: $be;
	border-bottom-color: $be;
}
.green-link{
	@extend %link;
	color: $gn;
	border-bottom-color: $gn;
	&_dash{
		border-bottom-style: dashed;
	}
	&_rev{
		border-bottom-color: transparent;
		&:hover{
			border-bottom-color: $gn;
		}
	}
}