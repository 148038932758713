//after-before
%ab{
	display: block;
	content: "";
}

//fullWidthHeight
%fwh{
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

//responsive image
%r-img{
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}

//flex center
%fx-c{
	display: flex;
	align-items: center;
	justify-content: center;
}