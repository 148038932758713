// font family
$ff: 'helveticaneue', sans-serif;

// colors
$bk: #212121;
$gy: #f1f1f1;
$dgy: #a7a7a7;
$rd: #ff0000;
$gn: #74c447;
$gngy: #afc5a3;
$be: #0000ff;
$yw: #ffff00;
$vt: #895df7;